<template>
  <v-dialog
    ref="localModelOpened"
    v-model="localModelOpened"
    persistent
    width="460px"
  >
    <validation-observer ref="observer">
      <v-form @submit.prevent="submit" ref="observer_form">
        <v-card class="modalclass pb-10">
          <div class="d-flex justify-space-between">
            <v-card-title>
              {{ $t('page_link_posts_edit_title') }}
            </v-card-title>
            <v-btn color="red" text @click="closeModel()">x</v-btn>
          </div>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="field in fields" :key="field.name" class="input-row">
                  <td>
                    <v-label>{{ field.label }}</v-label>
                  </td>
                  <td v-if="field.type === 'text'">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="field.name"
                      :rules="field.rules"
                    >
                      <v-text-field
                        :placeholder="field.placeholder"
                        dense
                        class="input-class widthfull"
                        v-model="field.value"
                        :error-messages="errors"
                      >
                      </v-text-field>
                    </validation-provider>
                  </td>

                  <td v-if="field.type === 'date'">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="field.name"
                      :rules="field.rules"
                    >
                      <v-dialog
                        ref="post_date_dialog"
                        v-model="field.model"
                        :return-value.sync="field.value"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :placeholder="field.placeholder"
                            dense
                            class="input-class widthfull"
                            v-model="field.value"
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker v-model="field.value" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="field.model = false"
                          >
                            {{ $t('cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.post_date_dialog[0].save(field.value)"
                          >
                            {{ $t('ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </validation-provider>
                  </td>

                  <td v-if="field.type === 'toggle'">
                    <validation-provider
                      :name="field.name"
                      :rules="field.rules"
                    >
                      <toggle-button
                        v-model="field.value"
                        :color="colorObj"
                        style="float: left"
                        :width="42"
                        :value="false"
                        :height="19"
                        :sync="true"
                      />
                      <span
                        :class="[
                          field.value
                            ? 'tgl-btn-class-green'
                            : 'tgl-btn-class-red'
                        ]"
                      >
                        {{ field.value ? $t('publish') : $t('private') }}
                      </span>
                    </validation-provider>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="btn-center">
            <v-btn
              type="submit"
              :disabled="formStatus"
              :loading="formStatus"
              class="btn-class-submit"
            >
              {{ $t('submit') }}
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import toggleButton from 'vue-js-toggle-button'
Vue.use(toggleButton)

export default {
  data() {
    return {
      colorObj: { checked: '#046C21', unchecked: '#D14141' },
      localModelOpened: false,
      editItem: {},
      fields: [
        {
          label: this.$t('page_link_posts_edit_field_post_date'),
          name: 'post_date',
          type: 'date',
          placeholder: this.$t(
            'page_link_posts_edit_field_post_date_placeholder'
          ),
          value: '',
          model: false,
          rules: 'required'
        },
        {
          label: this.$t('page_link_posts_edit_field_title'),
          name: 'title',
          type: 'text',
          placeholder: this.$t('page_link_posts_edit_field_title_placeholder'),
          value: '',
          rules: 'required'
        },
        {
          label: this.$t('page_link_posts_edit_field_url'),
          name: 'url',
          type: 'text',
          placeholder: this.$t('page_link_posts_edit_field_url_placeholder'),
          value: '',
          rules: 'required'
        },
        {
          label: this.$t('page_link_posts_edit_field_publisher'),
          name: 'publisher',
          type: 'text',
          placeholder: this.$t(
            'page_link_posts_edit_field_publisher_placeholder'
          ),
          value: '',
          rules: 'required'
        },
        {
          label: this.$t('page_link_posts_edit_field_status'),
          name: 'status',
          type: 'toggle',
          placeholder: this.$t('page_link_posts_edit_field_status_placeholder'),
          value: true,
          rules: 'required'
        }
      ],
      formStatus: false
    }
  },
  created() {
    let this_this = this
    this.$root.$on('openEditModel', function(value) {
      this_this.localModelOpened = true
      this_this.editItem = value
      this_this.editItemFun(value)
    })
  },
  methods: {
    closeModel() {
      this.localModelOpened = false
    },
    editItemFun(item) {
      for (const field of this.fields) {
        if (field.additional_field) {
          field.additional_field.value = item[field.additional_field.name]
        }
        if (field.name === 'status') {
          field.value = item[field.name] === 1
          continue
        }
        field.value = item?.[field.name]
      }
    },
    async submit() {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        let data = {}

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, 'additional_field')) {
            data[field.additional_field.name] = field.additional_field.value
          }
          data[field.name] = field.value
        })

        data.id = this.editItem.id

        this.$store
          .dispatch('EDIT_LINK_POST', data)
          .then(result => {
            if (result.status === 200) {
              this.$refs.observer.reset()
              this.$refs.observer_form.reset()
            }
            this.formStatus = false
            this.localModelOpened = false
            this.editItem = {}
            this.$root.$emit('onSavePostLink', true)
          })
          .catch(error => {
            this.formStatus = false
            this.$refs.observer.setErrors(error.data.error.errors)
          })
      })
    },

    getToggleColor(value) {
      if (value) return '#046C21'
      return '#D14141'
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
