var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"localModelOpened",attrs:{"persistent":"","width":"460px"},model:{value:(_vm.localModelOpened),callback:function ($$v) {_vm.localModelOpened=$$v},expression:"localModelOpened"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"observer_form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticClass:"modalclass pb-10"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('page_link_posts_create_title'))+" ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.closeModel()}}},[_vm._v("x")])],1),_c('v-divider'),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.fields),function(field){return _c('tr',{key:field.name},[_c('td',[_c('v-label',[_vm._v(_vm._s(field.label))])],1),(field.type === 'text')?_c('td',[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-class widthfull",attrs:{"placeholder":field.placeholder,"dense":"","error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1):_vm._e(),(field.type === 'date')?_c('td',[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-dialog',{ref:"post_date_dialog",refInFor:true,attrs:{"return-value":field.value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(field, "value", $event)},"update:return-value":function($event){return _vm.$set(field, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-class widthfull",attrs:{"placeholder":field.placeholder,"dense":"","error-messages":errors},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(field.model),callback:function ($$v) {_vm.$set(field, "model", $$v)},expression:"field.model"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){field.model = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.post_date_dialog[0].save(field.value)}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)]}}],null,true)})],1):_vm._e(),(field.type === 'toggle')?_c('td',[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules}},[_c('toggle-button',{staticStyle:{"float":"left"},attrs:{"color":_vm.colorObj,"width":42,"value":field.value,"height":19},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('span',{class:[
                        field.value
                          ? 'tgl-btn-class-green'
                          : 'tgl-btn-class-red'
                      ]},[_vm._v(" "+_vm._s(field.value ? _vm.$t('publish') : _vm.$t('private'))+" ")])],1)],1):_vm._e()])}),0)]},proxy:true}])}),_c('div',{staticClass:"btn-center"},[_c('v-btn',{staticClass:"btn-class-submit",attrs:{"type":"submit","disabled":_vm.formStatus,"loading":_vm.formStatus}},[_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }